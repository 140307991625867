import { ProductVariant } from "@medusajs/medusa"

const boxersSKUs = [
  'SWBS-PB',
  'SWBS-AB',
  'SWBS-SB',
  'SWBS-VS',
]

export const isBoxersItem = (sku: string) => {
  if (!sku) {
    return false
  }

  if (boxersSKUs.some((boxerSKU) => sku.startsWith(boxerSKU))) {
    return true
  }

  return false
}