import * as React from "react"
import { Box, BoxProps } from "theme-ui"

export const Divider: React.FC<BoxProps> = ({ sx, ...boxProps }) => {
  return (
    <Box
      as="hr"
      sx={{ backgroundColor: "grayscale.200", ...sx }}
      {...boxProps}
    />
  )
}
