export const getSizeAcronym = (size: string) => {
  return size === "XXtra Small"
    ? "XXS"
    : size === "Xtra Small" || size === "Extra Small"
    ? "XS"
    : size === "Small"
    ? "S"
    : size === "Medium"
    ? "M"
    : size === "Large"
    ? "L"
    : size === "Xtra Large" || size === "Extra Large"
    ? "XL"
    : size === "Xxtra Large"
    ? "XXL"
    : size
}
