import { LineItem } from "@medusajs/medusa"

export const calculateItemCount = (items: LineItem[] | []): number => {
  if (!items?.length) {
    return 0
  }

  let total = 0

  for (const item of items) {
    total += item?.quantity || 0
  }

  return total
}
