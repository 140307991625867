export default (cart) => {
  const displayItems = {}

  let wrappingId
  if (cart?.items) {
    for (const i of cart.items) {
      if (i.metadata?.is_wrapping) {
        wrappingId = i.id
        if (
          displayItems[i?.metadata?.unique_id] &&
          !displayItems[i.metadata.unique_id]?.ids?.includes(wrappingId)
        ) {
          displayItems[i.metadata.unique_id].ids = [
            ...displayItems[i.metadata.unique_id].ids,
            i.id,
          ]
        }

        continue
      }

      if (i.metadata && i.metadata.unique_id) {
        const bundleId = i.metadata.unique_id
        if (displayItems[bundleId]) {
          const current = displayItems[bundleId]
          displayItems[bundleId] = {
            ...current,
            ids: [...current.ids, i.id],
            thumbnail: i.metadata.bundle_thumbnail || current.thumbnail,
            second_product_label: current.title,
            original_total: current.original_total + i.original_total,
          }
        } else {
          displayItems[bundleId] = {
            ...i,
            ids: [i.id],
            is_bundle: true,
            quantity: 1,
            original_total: i.original_total,
            title: i.metadata.bundle_title || i.title,
            thumbnail: i.metadata.bundle_thumbnail,
            unique_bundle_id: bundleId,
            first_product_label: i.title,
          }
        }

        if (wrappingId) {
          displayItems[i.metadata.unique_id].ids = [
            ...displayItems[i.metadata.unique_id].ids,
            wrappingId,
          ]
        }
      } else if (i.metadata && i.metadata.is_gc_printed) {
        const bundleId = i.metadata.bundle_id
        const gc = cart?.items?.find(i => i.is_giftcard === true && i.metadata.bundle_id === bundleId)
        if (displayItems[bundleId]) {
          const current = displayItems[bundleId]
          displayItems[bundleId] = {
            ...current,
            ids: [...current.ids, i.id],
            original_total: current.original_total + i.original_total,
            title: gc?.title,
            thumbnail: gc?.thumbnail,
          }
        } else {
          displayItems[bundleId] = {
            ...i,
            ids: [i.id],
            quantity: gc?.quantity,
            original_total: i.original_total,
            title: gc?.title,
            is_bundle: true,
            thumbnail: gc?.thumbnail,
            unique_bundle_id: bundleId,
            first_product_label: "",
          }
        }
      } else {
        if (displayItems.simple) {
          displayItems.simple.push(i)
        } else {
          displayItems.simple = [i]
        }
      }
    }
  }

  let items = []
  if (displayItems) {
    for (const [key, val] of Object.entries(displayItems)) {
      if (key === "simple") {
        items = items.concat(val)
      } else {
        items.push(val)
      }
    }
  }

  return items
}
