import * as React from "react"
import { Box, BoxProps, Flex, Input, InputProps } from "theme-ui"

export interface NumberInputButtonProps extends BoxProps {
  addDisabled?: NumberInputProps["addDisabled"]
  removeDisabled?: NumberInputProps["removeDisabled"]
}

const NumberInputButton: React.FC<NumberInputButtonProps> = ({
  addDisabled,
  removeDisabled,
  children,
  ...boxProps
}) => {
  return (
    <Box
      as="button"
      sx={{
        width: 6,
        height: 6,
        color: addDisabled || removeDisabled ? "grayscale.400" : "primary",
        fontSize: "xs",
        backgroundColor: "transparent",
        border: "1px solid",
        borderColor: "grayscale.200",
        borderRadius: "50%",
        cursor: addDisabled || removeDisabled ? "not-allowed" : "pointer",
        transition: "color .2s, border-color .2s",
        padding: 0,
        ":hover": {
          color: addDisabled || removeDisabled ? "grayscale.400" : "primary",
          borderColor:
            addDisabled || removeDisabled ? "grayscale.200" : "grayscale.300",
        },
      }}
      {...boxProps}
    >
      {children}
    </Box>
  )
}

export interface NumberInputProps extends InputProps {
  value: number
  min?: number
  max?: number
  addDisabled?: boolean
  removeDisabled?: boolean
  onNumberChange?: (value: number) => void
}

export const NumberInput: React.FC<NumberInputProps> = ({
  value,
  min,
  max,
  addDisabled,
  removeDisabled,
  onNumberChange,
  sx,
  ...inputProps
}) => {
  const updateValue = (input: number) => {
    const val =
      typeof max !== "undefined"
        ? Math.min(
            max,
            typeof min !== "undefined" ? Math.max(min, input) : input
          )
        : input
    if (value !== val) {
      onNumberChange(val)
    }
  }

  return (
    <Flex sx={{ ...sx }}>
      <NumberInputButton
        removeDisabled={removeDisabled}
        onClick={() => {
          updateValue(value - 1)
        }}
      >
        -
      </NumberInputButton>
      <Input
        value={value}
        sx={{
          flex: 1,
          textAlign: "center",
          border: 0,
          padding: 0,
          marginInlineEnd: 2,
          marginInlineStart: 2,
          outline: "none",
        }}
        {...inputProps}
      />
      <NumberInputButton
        addDisabled={addDisabled}
        onClick={() => {
          updateValue(value + 1)
        }}
      >
        +
      </NumberInputButton>
    </Flex>
  )
}
